<template>
  <v-card> 

    <v-dialog v-model="showAlertModal" max-width="450">
      <v-card>
        <v-card-text>
          <div id="icon">
          <v-icon @click="showAlertModal = false" color="primary" id="icon-child" size="45"  >
            {{ icons.mdiCloseCircleOutline }}
          </v-icon>
        </div>

          <v-container>
            <span class="text-h6">The form is ideally filled by Counsellor Cum Technician.
              <br />
              This form is <strong> Not filled</strong>
              yet.</span>
            <br /><br />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-card-title class="justify-center pt-8">
      <span class="text-h5"><strong> EYE DONOR MEDICAL PARTICULARS</strong></span>
    </v-card-title>
    <v-row class="mx-5 pt-3">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Name of the Deceased:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field 
          v-model="ValueList.donor_name" 
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="6" md="4">
        <p class="body-2 mb-0">Age:</p>
        <v-text-field 
          v-model="ValueList.age" 
          readonly 
          @paste.prevent
          @keypress="onlyAge"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="4">
        <p class="body-2 mb-0">Sex:</p>
        <v-autocomplete 
          v-model="ValueList.sex" 
          :items="Sex"
          readonly
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mb-0">Permanent Address:</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-textarea 
          v-model="ValueList.permanent_add" 
          hide-details 
          row-height="15" 
          rows="2" 
          class="pt-0 mt-0"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Date & Time of Death:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field 
          v-model="timeOfDeath" 
          readonly 
          date-format="dd-MM-yyyy"
          time-format="HH:mm"
        > </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Place of Death:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field 
          v-model="ValueList.place_of_death"
          :readonly="readOnlyField1 === true"
          :error="errr === false"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Manner of Death:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete 
          v-model="ValueList.manner_of_death" 
          :items="items"
          :readonly="readOnlyField1 === true"
          :error="errr1 === false"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Cause of Death:</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-textarea 
          v-model="ValueList.cause_of_death" 
          row-height="15" 
          rows="2"
          readonly
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Secondary Causes:</p>
      </v-col>
      <v-col cols="12" sm="8">
        <v-textarea 
          v-model="ValueList.secondary_causes" 
          row-height="15" 
          rows="2"
          :readonly="readOnlyField1 === true"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Visible Identification Marks:</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-text-field 
          v-model="ValueList.visitle_id_mark"
          :readonly="readOnlyField1 === true"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Name of the person giving consent:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field 
          v-model="ValueList.info_given_by_next_of_kin"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Relationship with the donor:</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field 
          v-model="ValueList.relationship_with_donor" 
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Death Certificate</p>
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete   
          v-model="ValueList.death_certificate" 
          :items="['Available', 'Not avaliable']"
          :readonly="readOnlyField1 === true"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="mx-5" v-if="ValueList.death_certificate == 'Not avaliable'">
      <v-col cols="12" md="4">
        <p class="body-2 mt-5 mb-0">Reason for not obtaining Death Certificate copy</p>
      </v-col>
      <v-col cols="12" md="8">
        <v-textarea 
          v-model="ValueList.reason_of_not_obtaining_death_certificate" 
          row-height="15" 
          rows="2"
          :readonly="readOnlyField1 === true"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="5">
        <p class="body-2 mb-0">The Certificate is under process & will be submitted later</p>
      </v-col>
      <v-col cols="12" md="3">
        <v-file-input 
          v-model="signature" 
          chips 
          accept="image/*" 
          label="Signature of the Consentee" 
          @change="onFileChange" 
          :readonly="readOnlyField1 === true"
        />
        <v-text-field 
          v-model="ValueList.name_of_next_of_kin" 
          label="Name of Consentee"
          readonly
        ></v-text-field>
      </v-col> 
       <v-col v-if="!signatureObj && ValueList.next_of_kin_sign" cols="3">
          <img  v-img style="width:82%" :src="!signatureObj?`https://api.ebsr.in/${ValueList.next_of_kin_sign}`:''" />
       </v-col>
        <v-col v-if="!!signatureObj"  cols="3">
            <img v-img style="width: 82%;" :src="signatureObj?signatureObj:''" />
        </v-col>
      <!-- <v-col cols="12" md="2">
        <img v-img style="width:100%" :src="`https://api.ebsr.in/${ValueList.next_of_kin_sign}`" />
      </v-col> -->
    </v-row>

    <v-row class="mx-5 mb-12 pt-10">
      <v-col cols="12" md="4">
        <!-- <p class="mt-2">I have by certify the death of</p> -->
        <p class="mt-5">I hereby certify the death of</p>
      </v-col>
      <v-col cols="12" md="1">
        <v-autocomplete 
         v-model="ValueList.mr_mrs_ms"
          :items="Mr"
          :readonly="readOnlyField1 === true"
          :error="errr2 === false"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field 
          v-model="ValueList.certify_death_of" 
          label="Name"
          readonly
        ></v-text-field>
      </v-col>
      <v-col ols="12" md="4"></v-col>
      <v-col ols="12" md="5"></v-col>
      <v-col ols="12" md="3">
        <v-file-input 
          v-model="doctorSignature" 
          chips 
          accept="image/*" 
          label="Signature" 
          @change="onFileclick" 
          :readonly="readOnlyField1 === true"
        />
        <span class="body-2">(Doctor attending Eye donation call)</span>
      </v-col>
       <v-col v-if="!doctorSignatureObj && ValueList.eye_donation_doctor_sign" cols="3">
          <img  v-img style="width:82%" :src="!doctorSignatureObj?`https://api.ebsr.in/${ValueList.eye_donation_doctor_sign}`:''" />
       </v-col>
        <v-col v-if="!!doctorSignatureObj"  cols="3">
            <img v-img style="width: 82%;" :src="doctorSignatureObj?doctorSignatureObj:''" />
        </v-col>
      <!-- <v-col cols="12" md="2">
        <img v-img style="width:100%" :src="`https://api.ebsr.in/${ValueList.eye_donation_doctor_sign}`" />
      </v-col> -->
    </v-row>
    <v-row class="mx-5 mb-3">
      <v-col cols="12" md="4">
        <v-datetime-picker 
          v-model="date_time1" 
          date-format="dd-MM-yyyy"
          time-format="HH:mm" 
          label="Date and Time"
          :readonly="readOnlyField1 === true"
        > </v-datetime-picker>
      </v-col>
      <v-col cols="12" md="4"></v-col>
      <v-col cols="12" md="4">
        <v-text-field 
          v-model="ValueList.doctor_name" 
          label="Name"
          :readonly="readOnlyField1 === true"
        ></v-text-field>
        <v-text-field 
          v-model="ValueList.reg_no" 
          label="Regn No" 
          required 
          @paste.prevent
          :readonly="readOnlyField1 === true"
          @keypress="onlyNumber"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-5">
      <v-col cols="12" md="12" class="text-right">
        <!-- <v-btn color="darken-1" text @click="addUserModal = false"> Cancel </v-btn> -->
        <v-btn color="primary" class="mb-6" v-if="saveBtn" @click="same()"> Save </v-btn>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<style>
 #icon{
  width:100%;
  text-align: right;    
 }

 #icon-child{
  margin-right: -10px;
  margin-top: 5px;
 }
</style>
<script>
import donorService from '@/service/Donor.service'
import { mdiMagnify, mdiCloseCircleOutline, mdiGithub } from '@mdi/js'
const baseUrl = 'https://api.ebsr.in/'
import moment from 'moment'
import axios from 'axios'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const userId = localData.user_id


export default {
  data() {
    return {
      errr:true,
      errr1:true,
      errr2:true,

      formtype:'',
      signatureObj:'',
      readOnlyField1:false,
      saveBtn:true,
      doctorSignatureObj:'',
      totallist: 0,
      showAlertModal: false,
      snackbar: false,
      snackbarText: '',
      ValueList: {
        date_time: '',
      },
      doctorSignature:null,
      image: null,
      signature:null,
      listLoading: false,
      timeout: 2000,
      death_certificate: '',
      addUserModal: false,
      Mr:['Mr.','Mrs.','ms.'],
      // relation: ['Son', 'Wife', 'Daughter'],
      items: ['Natural', 'Accident', 'Suicide', 'Homicide', 'Pending investigation'],
      Death: ['Death Certificate', 'Onsite Verification'],
      Sex: ['Male', 'Female'],
      Body: ['Average', 'Overweight', 'Underweight'],
      textFieldProps: {
        outlined: true,
        dense: true,
        'hide-details': true,
      },
    }
  },

  setup() {


return {
  // Icons
  icons: {
    mdiCloseCircleOutline,
  },
}
},

  computed: {

    timeOfDeath: {
      get() {
        return moment.utc(this.ValueList.date_time_of_death).format('DD-MM-YYYY HH:mm')
      },
    },

    date_time1: {
      get() {
        return moment.utc(this.ValueList.date_time).format('DD-MM-YYYY HH:mm')
      },
      set(value) {
        this.$set(this.ValueList, 'date_time', moment(value).format('YYYY-MM-DD HH:mm'))
      },
    },

  },

  watch: {
    options: {
      handler() {
        this.getretrieval()
        this.getdonorList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.getretrieval()
    this.getdonorList()
  },

  methods: {

    same(){
      if(this.ValueList.place_of_death == "" || this.ValueList.place_of_death == null){
        this.errr = false
        return false,
        this.snackbarText = "Please Fill the Place of death.",
        this.snackbar = true
      }else if(this.ValueList.manner_of_death == "" || this.ValueList.manner_of_death == null){
        this.errr1 = false
        return false,
        this.snackbarText = "Please Fill the manner of death",
        this.snackbar = true 
      }
      else if(this.ValueList.mr_mrs_ms == "" || this.ValueList.mr_mrs_ms == null){
        this.errr2 = false
        return false,
        this.snackbarText = "Please Select Mr/Mrs/Ms",
        this.snackbar = true 
      }else if(this.ValueList.date_time == "" || this.ValueList.date_time == 'Invalid date'){
        this.ValueList.date_time = null
      }else{
        this.errr = true
        this.errr1 = true
        this.errr2 = true
      }
    //   else if(this.ValueList.eye_donation_doctor_sign == "" || this.ValueList.eye_donation_doctor_sign == null){
    //     return false,
    //     this.snackbarText = "Please Fill the Signature ( Doctor attending eye donation call)",
    //     this.snackbar = true 
    //   }
    //   else if(this.ValueList.doctor_name == "" || this.ValueList.doctor_name == null){
    //     return false,
    //     this.snackbarText = "Please Fill the Name",
    //     this.snackbar = true 
    //   }
    //   else if(this.ValueList.reg_no == "" || this.ValueList.reg_no == null){
    //     return false,
    //     this.snackbarText = "Please Fill the Regn No",
    //     this.snackbar = true 
    //   }
      this.RetirevalForm()
      
    },
 
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault()
      }
    },

    onlyAge($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault()
      }
    },

    async getretrieval() {
      const DonorId = this.$route.params.donor_id
      const service = new donorService()
      let response = await service.retrieval_form(DonorId)
      this.ValueList = response
    },

     async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.donorList()
      if (response) {
        this.permissionData = response.permissions
        var formData = this.permissionData[5]
        this.formtype = this.permissionData[3]
        
        // console.log("heloo",formData)
      if(formData.view == "1" && formData.edit == null || formData.view == "1" && formData.edit == "0"){
          this.readOnlyField1 = true
          this.saveBtn = false

          if (!this.ValueList.id) {
            this.showAlertModal = true
          }
        } 
      }else {
      this.totallist = 0
      }
      this.listLoading = false
    },


    onFileChange() {
      this.signatureObj=URL.createObjectURL(this.signature)
      const reader = new FileReader()
      reader.readAsDataURL(this.signature)
      reader.onload = e => {
        
        if(e.total > 5242880){
          this.snackbar = true;
          this.snackbarText = "Please upload file below 5 MB";
          this.signature = null;
          this.signatureObj = null;
          return;
        }

        this.ValueList.next_of_kin_sign = e.target.result
      }
    },
    
    onFileclick() {
      this.doctorSignatureObj=URL.createObjectURL(this.doctorSignature)
      const reader = new FileReader()
      reader.readAsDataURL(this.doctorSignature)
      reader.onload = e => {

        if(e.total > 5242880){
          this.snackbar = true;
          this.snackbarText = "Please upload file below 5 MB";
          this.doctorSignature = null;
          this.doctorSignatureObj = null;
          return;
        }

        this.ValueList.eye_donation_doctor_sign = e.target.result
      }
    },

    async RetirevalForm() {
      
      const donorid = this.$route.params.donor_id
      const data = {
        user_id: userId,
        donor_id: donorid,
        place_of_death: this.ValueList.place_of_death,
        permanent_address:this.ValueList.permanent_add,
        manner_of_death: this.ValueList.manner_of_death,
        secondary_causes: this.ValueList.secondary_causes,
        visitle_id_mark: this.ValueList.visitle_id_mark,
        death_certificate: this.ValueList.death_certificate,
        reason_of_not_obtaining_death_certificate: this.ValueList.reason_of_not_obtaining_death_certificate,
        next_of_kin_sign:this.ValueList.next_of_kin_sign,
        name_of_next_of_kin:this.ValueList.name_of_next_of_kin,
        mr_mrs_ms:this.ValueList.mr_mrs_ms,
        eye_donation_doctor_sign:this.ValueList.eye_donation_doctor_sign,
        doctor_name: this.ValueList.doctor_name,
        reg_no: this.ValueList.reg_no,
        date_time: this.ValueList.date_time,
      }
      try {
        const response = await axios.post(`${baseUrl}eyedonormedical/add`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        })
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.snackbar = true
          if(this.formtype.view != '0' && this.formtype.view != null){
            this.$router.push({name:'Form_C'});
          }
          this.getretrieval()
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

  },
}
</script>
